import React, { useState, useEffect } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import { useData } from '../../DataProvider';
import { getAllowedPayers, getAllowedPayees, getUserRole, getUserEmail } from '../utils/authHelper.js';
import { toast } from 'react-toastify';
import sdk from '@hopdrive/sdk';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, Container, Grid, Typography, Button } from '@material-ui/core';
import Divide from '../reusable/Divide';

import gql from 'graphql-tag';
import fragments from '../utils/graphQL/fragments';
import { Query, Subscription } from 'react-apollo';

import Loading from '../utils/Loading';
import WorkflowForm from './movePlanner/WorkflowForm';
import DisabledMovePlanner from './movePlanner/DisabledMovePlanner';
import PayerSelect from './movePlanner/PayerSelect';
import TypeForm from './movePlanner/TypeForm';
import CustomerForm from './movePlanner/CustomerForm';
import LaneForm from './movePlanner/LaneForm';
import NotificationManagement from '../reusable/NotificationManagement';
import LocationSelect from './movePlanner/LocationSelect';
import DatetimeSelect from './movePlanner/DatetimeSelect';
import ConsumerInfoForm from './movePlanner/ConsumerInfoForm';
import NotesInput from './movePlanner/NotesInput';
import SequenceButtonGroup from './movePlanner/SequenceButtonGroup';
import VehicleForm from './movePlanner/VehicleForm';
import ConsumerLocButtonGroup from './movePlanner/ConsumerLocButtonGroup';
import DealerContactInput from './movePlanner/DealerContactInput';

const log = false;

const getStartTime = () => {
  let start = moment().startOf(`minute`).add(90, `minutes`);
  const addStep = 10 - (start.minutes() % 10);
  start = start.add(addStep, `minutes`);
  return moment.utc(start);
};

const defaultWorkflow = {
  id: 1,
  pickup_workflow_id: 1,
  delivery_workflow_id: 2,
};

const defaultSla = {
  id: 1,
  name: 'default',
  label: 'HopDrive Default',
  duration_hrs: 48,
};

const conciergeSla = {
  id: 2,
  name: 'default_concierge',
  label: 'HopDrive Concierge',
  duration_hrs: 1,
};

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function MovePlanner(props) {
  const ctx = useData();
  const cls = useStyles();

  // We need to add consumer pickup boolean to the move record
  // We need to add consumer name and phone to the move record
  // How to calc the pickup time of consumer appt (sequence === 0)
  //   let total_move_time = duration_sec + pickup_inspection_sec + delivery_inspection_sec
  //   consumer pickup pickup time = move.sequence[1].pickup_time - total_move_time
  // How to calc the pickup time of subsequent moves (sequence > 0)
  //   take the pickup_time of move[0].pickup_time and add total_move_time of
  //   each subsequent move to that move's pickup_time
  // let total_move_time = 0
  // moves.forEach(move => {
  //   if (move.sequence == 0) continue
  //   total_move_time += move.lane.duration_sec + move.lane.pickup_inspection_sec + move.lane.delivery_inspection_sec
  //   move.pickup_time = move[0].pickup_time + total_move_time
  // });
  // Temp ideation on object state for form

  // State
  const [workflow, setWorkflow] = useState({});
  const [payerId, setPayerId] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [type, setType] = useState('concierge');
  const [now, setNow] = useState(moment());
  const [newLaneId, setNewLaneId] = useState(null);
  const [subscriptionSkip, setSubscriptionSkip] = useState(true);
  const [doesLaneExist, setDoesLaneExist] = useState(false);
  const [moves, setMoves] = useState([
    {
      index: 0,
      pickupTime: getStartTime(),
      consumerPickup: true,
      validation: {
        pickup: true,
        delivery: true,
        ptime: true,
        pdate: true,
        stock: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        color: true,
        cname: true,
        cnumber: true,
        region: true,
      },
    },
    {
      index: 1,
      pickupTime: getStartTime(),
      consumerPickup: false,
      validation: {
        pickup: true,
        delivery: true,
        ptime: true,
        pdate: true,
        stock: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        color: true,
        cname: true,
        cnumber: true,
        region: true,
      },
    },
  ]);
  const [moveCount, setMoveCount] = useState(1);
  const [lane, setLane] = useState(null);
  const [defaultLane, setDefaultLane] = useState(null);
  const [sequenceA, setSequenceA] = useState(0); // When sequenceA = 0, move in sequence 1 is a loaner vehicle
  const [sequenceB, setSequenceB] = useState(1);
  const [customerId, setCustomerId] = useState(parseInt(ctx.customerOverride || ctx.customerId || 0));
  const [lanePair, setLanePair] = useState(null);
  const [isSubscriptionRendering, setIsSubscriptionRendering] = useState(false);
  const [pickupDeliveryChange, setPickupDeliveryChange] = useState(null);
  const [payersArray, setPayersArray] = useState([]);
  const [payeesArray, setPayeesArray] = useState([]);
  const [emailsList, setEmailsList] = useState([]);

  const [loading, setLoading] = useState(false);

  // const validMoveTypes = ['one-way', 'round-trip', 'milk-run', 'concierge', 'loaner'];
  const roundTripMoveTypes = ['round-trip', 'loaner'];
  // const oneWayMoveTypes = ['one-way', 'concierge'];

  const [consumerLocation, setConsumerLocation] = useState('pickup');

  useEffect(() => {
    if (ctx.customerOverride) {
      setCustomerId(parseInt(ctx.customerOverride));
    } else if (ctx.customerId) {
      setCustomerId(parseInt(ctx.customerId));
    }
  }, [ctx.customerOverride, ctx.customerId]);

  useEffect(() => {
    if (customerId) {
      checkEmailConfig(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    /* 
    The third party payer array is set two different ways:
    1) If the customer has third party payers assigned to them, then the array will be populated with the third party payers' ids
    2) If the customer is a third party payer themselves, the dropdown will be populated with their "allowed_customers" array, meaning they can select themseleves as a payer when creating a move for a different customer
    The way we detect if the customer is a third party payer is by checking if they have a "payer-child-customers" array
    */
    const getAllowedPayersArray = async () => {
      let allowedPayers = await getAllowedPayers();
      let allowedPayees = await getAllowedPayees();

      if (!allowedPayers || !allowedPayers.length) {
        log && console.log('No payer array');
      }
      if (allowedPayers && allowedPayers.length) {
        log && console.log('User has allowed payers. Allowed payer array = allowed payers');
        setPayersArray(allowedPayers);
      }
      if (allowedPayees && allowedPayees.length) {
        log && console.log('User is a third party payer. allowed payer array = allowed customers');
        setPayeesArray(allowedPayees);
      }
    };
    getAllowedPayersArray();

    const fetchRole = async () => {
      let role = await getUserRole();
      setUserRole(role);
    };

    fetchRole();

    // Handle the inverse of a move
    if (props && props.location && props.location.state) {
      log && console.log('MOVE TO INVERSE', props.location.state.moveToInverse);
      if (props.location.state.moveToInverse) handlePreloadInverse(props.location.state.moveToInverse);
    }
  }, []);

  // Extra useEffect to force times to update upon changing types
  useEffect(() => {
    updatePickupTime();
  }, [type]);

  const checkEmailConfig = async customerId => {
    const configRes = await ctx.sdk.configs.getCustomerConfig(customerId);
    if (configRes && configRes.allow_email_notifications) {
      setEmailsList([getUserEmail()]);
    } else setEmailsList([]);
  };

  /**
   * Determines what move type to assign to a move or pair of moves based on the move object structure, then preloads the page with the appropriate vehicle and lane info for the inverse of the move.
   * @param {Object} move The move object passed in as a prop from the move details page
   * @returns {Function} Executes a series of functions to configure the move planner for the inverse of the provided move (or pair of moves)
   */
  async function handlePreloadInverse(move) {
    // Set customer and payer ids
    if (move.customer) setCustomerId(parseInt(move.customer.id));
    if (move.payer) setPayerId(move.payer.id);

    //Initially set lane as the non-inverse so if the code below fails, they still have a lane set and can use the "swap" button to get the inverse
    // setLane(move.lane)
    // Init temporary variables
    let type,
      moveQty,
      move1 = {},
      move2 = {},
      inverseLane = false,
      inverseLoaner = true,
      consumerAtPickup = true;

    // Function to create the object structure utilized by the MovePlanner component
    function handleMoveObj(move, objProp = false) {
      try {
        return {
          vehicle: {
            refNum: objProp ? move[objProp].reference_num : move.reference_num,
            manual: objProp ? move[objProp].manual_flag : move.manual_flag,
            stock: objProp ? { vehicle_stock: move[objProp].vehicle_stock } : { vehicle_stock: move.vehicle_stock },
            vin: objProp ? { vehicle_vin: move[objProp].vehicle_vin } : { vehicle_vin: move.vehicle_vin },
            make: objProp ? { name: move[objProp].vehicle_make } : { name: move.vehicle_make },
            model: objProp ? { name: move[objProp].vehicle_model } : { name: move.vehicle_model },
            year: objProp ? move[objProp].vehicle_year : move.vehicle_year,
            color: objProp ? move[objProp].vehicle_color : move.vehicle_color,
          },
          dealer_contact: objProp ? move[objProp].dealer_contact : move.dealer_contact,
          notes: objProp ? move[objProp].move_details : move.move_details,
        };
      } catch (err) {
        toast.warning(`Could not preload fields, please enter details manually.`);
        return {};
      }
    }
    // Try to determine the type of move(s) and set appropriate vehicle info
    try {
      //TODO: Remove this after we successfully transistion to new parent relationship
      const returnRideDriveMove =
        move.moveByReturnRideId && move.moveByReturnRideId.move_type === 'drive' ? move.moveByReturnRideId : null;
      const childDriveMove =
        move.childMoves &&
        Array.isArray(move.childMoves) &&
        move.childMoves[0] &&
        move.childMoves[0].move_type === 'drive'
          ? move.childMoves[0]
          : returnRideDriveMove;
      const parentMove = move.parentMove ? move.parentMove : move.parent_move ? move.parent_move : null;
      log && console.log('CHILD DRIVE MOVE', childDriveMove);
      log && console.log('PARENT MOVE', parentMove);
      if (!move.consumer_name && !move.consumer_phone) {
        // Move does not have a consumer associated
        if (!parentMove && !childDriveMove) {
          // Single move with no return ride associated
          type = 'one-way';
          moveQty = 1;
          move1 = handleMoveObj(move);
        } else if (parentMove) {
          if (parentMove.move_type === 'drive') {
            // Round trip move that was the second in a sequence of 2
            type = 'round-trip';
            moveQty = 2;
            move1 = handleMoveObj(move);
            move2 = handleMoveObj(parentMove);
          } else {
            // Single move with no return drive associated
            type = 'one-way';
            moveQty = 1;
            move1 = handleMoveObj(move);
          }
        } else if (childDriveMove) {
          // Round trip move that was the first in a sequence of 2
          type = 'round-trip';
          moveQty = 2;
          move1 = handleMoveObj(childDriveMove);
          move2 = handleMoveObj(move);
        } else {
          // Single move with no return ride associated
          type = 'one-way';
          moveQty = 1;
          move1 = handleMoveObj(move);
        }
      } else {
        // Move has a consumer associated
        if ((move.consumer_type === 'loaner' || (move.tags && move.tags.includes('loaner'))) && childDriveMove) {
          // Loaner-first C+L move that was the first in a sequence of 2
          type = 'loaner';
          moveQty = 2;
          move1 = handleMoveObj(childDriveMove);
          setSequenceA(1);
          move2 = handleMoveObj(move);
          setSequenceB(0);
          move2.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          inverseLoaner = false;
        } else if (
          (move.consumer_type === 'loaner' || (move.tags && move.tags.includes('loaner'))) &&
          parentMove &&
          parentMove.move_type === 'drive'
        ) {
          // Loaner-first C+L move that was the second in a sequence of 2
          type = 'loaner';
          moveQty = 2;
          move1 = handleMoveObj(move);
          setSequenceA(0);
          move2 = handleMoveObj(parentMove);
          setSequenceB(1);
          move2.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          inverseLane = true;
        } else if (move.consumer_type === 'customer' && childDriveMove && childDriveMove.move_type === 'drive') {
          // Customer-first C+L move that was the first in a sequence of 2
          type = 'loaner';
          moveQty = 2;
          move1 = handleMoveObj(childDriveMove);
          setSequenceA(0);
          move2 = handleMoveObj(move);
          setSequenceB(1);
          move2.consumer = { name: move.consumer_name, phone: move.consumer_phone };
        } else if (move.consumer_type === 'customer' && parentMove && parentMove.move_type === 'drive') {
          // Customer-first C+L move that was the second in a sequence of 2
          type = 'loaner';
          moveQty = 2;
          move1 = handleMoveObj(move);
          setSequenceA(1);
          move2 = handleMoveObj(parentMove);
          setSequenceB(0);
          move2.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          inverseLane = true;
          inverseLoaner = false;
        } else if (move.consumer_type === 'customer' && move.consumer_at_pickup) {
          type = 'concierge';
          moveQty = 1;
          move1 = handleMoveObj(move);
          move1.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          consumerAtPickup = false;
          inverseLoaner = false;
        } else if (move.consumer_type === 'customer' && !move.consumer_at_pickup) {
          type = 'concierge';
          moveQty = 1;
          move1 = handleMoveObj(move);
          move1.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          inverseLoaner = false;
        } else if (move.consumer_type === 'loaner' && move.consumer_at_pickup) {
          type = 'concierge';
          moveQty = 1;
          move1 = handleMoveObj(move);
          move1.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          consumerAtPickup = false;
        } else if (move.consumer_type === 'loaner' && !move.consumer_at_pickup) {
          type = 'concierge';
          moveQty = 1;
          move1 = handleMoveObj(move);
          move1.consumer = { name: move.consumer_name, phone: move.consumer_phone };
        }
      }
    } catch (err) {
      toast.warning('Could not preload fields, please enter details manually.');
      console.error('Failed to preload fields for inverse move:', err);
    }
    handleTypeChange(type, moveQty, inverseLoaner);
    if (!consumerAtPickup) setConsumerLocation('delivery');
    setMoves([Object.assign([...moves][0], move1), Object.assign([...moves][1], move2)]);
    try {
      const foundLane = await getLaneByLocationIds(move.lane.delivery.id, move.lane.pickup.id, inverseLane);
      if (foundLane) {
        handleLaneChange(foundLane);
      } else toast.warning('Could not set lane, please enter details manually.');
    } catch (err) {
      console.error('Failed to set lane for inverse move:', err);
      toast.warning('Could not set lane, please enter details manually.');
    }
  }
  const getLaneByLocationIds = async (pickupId, deliveryId, inverseLane = false) => {
    try {
      const newPickupId = inverseLane ? deliveryId : pickupId;
      const newDeliveryId = inverseLane ? pickupId : deliveryId;
      const res = await ctx.apolloClient.query({
        query: GET_LANES_BY_LOCATIONS,
        variables: { pickupId: newPickupId, deliveryId: newDeliveryId },
      });
      if (res.data && res.data.lanes.length > 0) {
        log && console.log(`Found lane:`, res.data.lanes[0]);
        return res.data.lanes[0];
      }
    } catch (err) {
      console.error(`Failed to find lane:`, err);
      toast.error(`Failed to find lane`);
    }
  };

  function resetValidation() {
    let _moves = [...moves];
    for (let move of _moves) {
      move.validation = {
        pickup: true,
        delivery: true,
        ptime: true,
        pdate: true,
        stock: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        color: true,
        cname: true,
        cnumber: true,
        region: true,
      };
    }
    setMoves(_moves);
  }

  function handleValidation(index, field, value) {
    let _moves = [...moves];
    _moves[index].validation[field] = value;
    setMoves(_moves);
  }

  const handleTypeChange = (type, count, inverseLoaner = false) => {
    setType(type);
    setMoveCount(count);

    if (type === 'one-way' || type === 'round-trip') {
      moves[0].consumerPickup = false;
      moves[1].consumerPickup = false;
    }

    if (type === 'concierge') {
      moves[0].consumerPickup = true;
      moves[1].consumerPickup = false;
      // if (!inverseLoaner) {
      //   setSequenceA(1);
      //   setSequenceB(0);
      // } else {
      //   setSequenceA(0);
      //   setSequenceB(1);
      // }
    }

    if (type === 'loaner') {
      moves[0].consumerPickup = true;
      moves[1].consumerPickup = true;
      // if (!inverseLoaner) {
      //   setSequenceA(0);
      //   setSequenceB(1);
      // } else {
      //   setSequenceA(1);
      //   setSequenceB(0);
      // }
      updatePickupTime('loaner'); // Forcing pickup times to update in case lane was set from a different type, causing both moves to have the same pickup time 90 minutes from current time
    }
  };

  const handleCustomerIdChange = customerId => {
    setCustomerId(parseInt(customerId));
  };

  const clearForm = () => {
    setMoves([
      {
        index: 0,
        pickupTime: getStartTime(),
        consumerPickup: true,
      },
      {
        index: 1,
        pickupTime: getStartTime(),
        consumerPickup: false,
      },
    ]);
  };

  // Function that detects state changes and applies them to the moves array
  const handleFormChange = (name, index) => data => {
    moves[index][name] = data;
  };

  // Called when the allowed datetime is changed
  const handleTimeChange = index => time => {
    log && console.log(`Time changed for move[${index}] to ${time.format()}`);

    moves[index].pickupTime = time.clone();
    updatePickupTime();
  };

  // Auto-calculate the opposite pickupTime
  const updatePickupTime = (forceAs = null) => {
    // Added an optional forceAs param to enable running the func before the setType func has resolved
    let currentType = type;
    if (forceAs) {
      currentType = forceAs;
    }
    switch (currentType) {
      case 'round-trip':
        log && console.log('Setting time for round trip move');
        //For round trips, we set the first move and calc the second
        if (moves && moves.length > 0) {
          if (moves[0] && moves[0].pickup && moves[0].delivery) {
            //If duration is 0 or missing, estimate the duration between the two locations
            //All moves have an estimated 18 minutes of inspection time
            let duration = 1080;
            const pickupLat = moves[0].pickup.latitude ? moves[0].pickup.latitude : null;
            const pickupLong = moves[0].pickup.longitude ? moves[0].pickup.longitude : null;
            const deliveryLat = moves[0].delivery.latitude ? moves[0].delivery.latitude : null;
            const deliveryLong = moves[0].delivery.longitude ? moves[0].delivery.longitude : null;
            //Can only estimate duration if we have the lat and long of both pickup and delivery
            if (pickupLat && pickupLong && deliveryLat && deliveryLong) {
              let estDriveTime = ctx.sdk.utilities.estimateDuration(
                [pickupLat, pickupLong],
                [deliveryLat, deliveryLong],
                60
              );
              duration = estDriveTime + duration;
            }
            //Set the pickup time for the second move based on the total
            // duration to get to the second from the first including
            // inspection time
            moves[1].pickupTime = moves[0].pickupTime.clone().add(duration, `seconds`);
            log &&
              console.log(
                `Set time for move[1] to (${moves[0].pickupTime.format()} + ${
                  duration / 60 / 60
                } hours) = ${moves[1].pickupTime.format()}`
              );
          } else {
            log && console.log('Cannot set time because locations are not known yet');
          }
        } else {
          log && console.log('Cannot set time because moves array has no entries');
        }
        break;
      case 'loaner':
        log && console.log('Setting time for concierge+loaner move');
        //For loaners, we set the second move and calc the first
        if (moves && moves.length > 0) {
          if (moves[0] && moves[0].pickup && moves[0].delivery) {
            //If duration is 0 or missing, estimate the duration between the two locations
            //All moves have an estimated 18 minutes of inspection time
            let duration = 1080;
            const pickupLat = moves[0].pickup.latitude ? moves[0].pickup.latitude : null;
            const pickupLong = moves[0].pickup.longitude ? moves[0].pickup.longitude : null;
            const deliveryLat = moves[0].delivery.latitude ? moves[0].delivery.latitude : null;
            const deliveryLong = moves[0].delivery.longitude ? moves[0].delivery.longitude : null;
            //Can only estimate duration if we have the lat and long of both pickup and delivery
            if (pickupLat && pickupLong && deliveryLat && deliveryLong) {
              let estDriveTime = ctx.sdk.utilities.estimateDuration(
                [pickupLat, pickupLong],
                [deliveryLat, deliveryLong],
                60
              );
              duration = estDriveTime + duration;
            }
            //Set the pickup time for the first move based on the total
            // duration to get to the first from the second including
            // inspection time
            moves[0].pickupTime = moves[1].pickupTime.clone().subtract(duration, `seconds`);
            log &&
              console.log(
                `Set time for move[0] to (${moves[1].pickupTime.format()} - ${
                  duration / 60 / 60
                } hours) = ${moves[0].pickupTime.format()}`
              );
            // If the auto-calculated time will push the first move to be within 90 minutes of the current time (or in the past), adjust the second move instead
            if (moment(moves[1].pickupTime.clone().subtract(duration, `seconds`)).diff(moment(), 'minutes') < 90) {
              moves[1].pickupTime = moves[0].pickupTime.clone().add(duration, `seconds`);
              log &&
                console.log(
                  `Auto-calc would have pushed move[0] into the past. Instead, set time for move[1] to (${moves[0].pickupTime.format()} + ${
                    duration / 60 / 60
                  } hours) = ${moves[1].pickupTime.format()}`
                );
            }
          } else {
            log && console.log('Cannot set time because lane is not known yet');
          }
        } else {
          log && console.log('Cannot set time because moves array has no entries');
        }
        break;
      default:
        log && console.log('required default case');
    }

    setNow(moment()); //Force the form to rerender
  };

  const handleLocationChange = moveIndex => async location => {
    if (moveIndex < 0) return;
    if (!location) return;
    await updateAllMoveLocationsAndLanes(moveIndex, location);
  };

  const updateAllMoveLocationsAndLanes = async (moveIndex, location) => {
    switch (moveIndex) {
      //First move pickup location changed
      case 0:
        //Update the lane object in the first move
        log && console.log('updateAllMoveLocationsAndLanes clearing pickup', location);
        moves[0].pickup = location;
        // await updateMoveLane(moves[0]);

        //Update the primary lane with the lane of the first move
        // setLane(moves[0].lane);

        //Set the pickupTime (lane duration sets second move's pickup time)
        updatePickupTime();

        //Validate that at least one of the two locations has a region
        const moveOneDeliveryLocationRegionIsNull = moves[0].delivery && !moves[0].delivery.region_id;
        if (location && !location.region_id && moveOneDeliveryLocationRegionIsNull) {
          //Toast warning - validation error
          toast.error(`At least one of the selected locations must be within the Hopdrive service radius.`);
        }

        if (roundTripMoveTypes.includes(type)) {
          //We can assume the second move is returning back here because its a round trip
          moves[1].delivery = location;
          // await updateMoveLane(moves[1]);

          //Handle updating the inverse lane if the form type is a variation of a round trip and
          // we successfully found a lane for the first move
          // await setMoveLaneInverse(moves[1], moves[0]);
        }

        break;

      //Second move pickup location changed
      case 1:
        //Update the pickup location of the second move
        moves[1].pickup = location;
        // await updateMoveLane(moves[1]);

        //Update the delivery of the first move to match
        moves[0].delivery = location;
        // await updateMoveLane(moves[0]);

        //Update the primary lane with the lane of the first move
        // setLane(moves[0].lane);

        //Set the pickupTime (lane duration sets second move's pickup time)
        updatePickupTime();

        //Validate that at least one of the two locations has a region
        const moveOnePickupLocationRegionIsNull = moves[0].pickup && !moves[0].pickup.region_id;
        if (location && !location.region_id && moveOnePickupLocationRegionIsNull) {
          //Toast warning - validation error
          toast.error(`At least one of the selected locations must be within the Hopdrive service radius.`);
        }

        //Handle updating the inverse lane if the form type is a variation of a round trip and
        // we successfully found a lane for the second move
        if (roundTripMoveTypes.includes(type)) {
          // await setMoveLaneInverse(moves[0], moves[1]);
        }
        break;

      default:
        break;
    }
  };

  //Upserts a bare-bones version of the lane object with only customer_id, pickup_id, delivery_id and description
  //This is enough to return a lane id that can be inserted along with the move.
  //A hasura event will detect that the lane is not finished and will 'enrich' the lane with its missing data after it has been inserted
  const handleLanes = async (customerId, pickup, delivery) => {
    if (!pickup) {
      return;
    }
    if (!delivery) {
      return;
    }
    if (!pickup.id) {
      return;
    }
    if (!delivery.id) {
      return;
    }
    if (pickup.id === delivery.id) return;
    if (!customerId || customerId < 1) {
      console.log('no customer id');
      return;
    }
    const lanePairRes = await ctx.sdk.lanes.handleLaneShellPair(
      customerId,
      pickup.id,
      delivery.id,
      pickup.name,
      delivery.name
    );
    if (!lanePairRes.success) {
      console.error('Lane failed to upsert');
      return [null, null];
    }
    if (lanePairRes.success && lanePairRes.laneOne && lanePairRes.laneTwo) {
      log && console.log('lanes upserted successfully');
      if (
        lanePairRes.laneOne.distance_miles === null ||
        lanePairRes.laneOne.dealer_stranded_price === null ||
        lanePairRes.laneOne.dealer_base_price === null
      ) {
        setNewLaneId(lanePairRes.laneOne.id);
      }

      // setPrimaryLane(lanePairRes.laneOne);
      setLanePair([lanePairRes[0], lanePairRes[1]]);
      return [lanePairRes.laneOne, lanePairRes.laneTwo];
    }
    console.error('Unknown error upserting lanes');
    return [null, null];
  };

  const handleLaneChange = async lane => {
    log && console.log('Handle lane change: ', lane);
    if (!lane) return;

    const inboundPickupId = lane && lane.pickup && lane.pickup.id ? lane.pickup.id : 0;
    const inboundDeliveryId = lane && lane.delivery && lane.delivery.id ? lane.delivery.id : 0;
    const movePickupId = moves[0] && moves[0].pickup && moves[0].pickup.id ? moves[0].pickup.id : 0;
    const moveDeliveryId = moves[0] && moves[0].delivery && moves[0].delivery.id ? moves[0].delivery.id : 0;

    if (inboundPickupId === 0 || inboundDeliveryId === 0) {
      setPickupDeliveryChange(true);
    } else setPickupDeliveryChange(false);

    //Handle the pickup location changing
    if (inboundPickupId !== movePickupId) {
      moves[0].pickup = lane.pickup;
      moves[1].delivery = lane.pickup;
      if (!lane.pickup && log) console.log('clearing pickup');
      await updateAllMoveLocationsAndLanes(0, lane.pickup);
    }

    //Handle the delivery location changing
    if (inboundDeliveryId !== moveDeliveryId) {
      log && console.log('move delivery id', movePickupId, 'inbound delivery id', inboundPickupId);
      moves[0].delivery = lane.delivery;
      moves[1].pickup = lane.delivery;
      if (!lane.delivery && log) console.log('clearing delivery');
      await updateAllMoveLocationsAndLanes(1, lane.delivery);
    }

    setLane(lane);
    setDefaultLane(lane);
  };

  // Function that handles the vehicle switch on the vehicle form
  const handleSwitchChange = index => labelIndex => {
    if ((index === 0 && labelIndex === 0) || (index === 1 && labelIndex === 1)) {
      setSequenceA(0);
      setSequenceB(1);
    }
    if ((index === 0 && labelIndex === 1) || (index === 1 && labelIndex === 0)) {
      setSequenceA(1);
      setSequenceB(0);
    }
  };

  const getSectionTitle = (index = 0) => {
    if (type === `round-trip`) return `Move ${index + 1}`;
    else if (type === `milk-run`) return `Stop ${index + 1}`;
    else if (type === `concierge`) {
      if (consumerLocation === `pickup`) return `Customer Location`;
      if (consumerLocation === `delivery`) return `Service Location`;
    } else if (type === `loaner` && index === 0) return `Service Location`;
    else if (type === `loaner` && index === 1) return `Customer Location`;
    else return `Move`;
  };

  const getSectionTip = (index = 0) => {
    if (type === ``) return ``;
    else return `Fill out the form for the vehicle at this location.`;
  };

  const getDatetimeTip = (index = 0) => {
    if ((type === `one-way` || type === `round-trip` || type === `milk-run`) && index === 0)
      return `The pickup time allows you to set a time when the vehicle should be picked up. Note that the pickup time is not exact and may differ from 5-10 minutes.`;
    else if (type === `round-trip` && index === 1)
      return `This pickup time is auto-calculated based on the first move's pickup time.`;
    else if (type === `milk-run` && index > 0)
      return `This pickup time is auto-calculated based on the previous stop's pickup time.`;
    else if (type === `concierge` || (type === `loaner` && index === 1))
      return `The pickup time for the customer's location should be based on the appointment time scheduled with your customer. Note that the pickup time is not exact and may differ from 5-10 minutes.`;
    else if (type === `loaner` && index === 0)
      return `This pickup time is auto-calculated based on the customer appointment time. Please adjust the scheduled appointment below to ensure this time is at least 90 minutes from the current time.`;
    else return null;
  };

  const disableTimeSelect = (index = 0) => {
    if (type !== `loaner` && index === 0) return false;
    else if (type === `loaner` && index === 1) return false;
    else return true;
  };

  const disableCustomerNotes = (index = 0) => {
    if ((type === `concierge` && index === 0) || (type === `loaner` && index === 1)) return false;
    else return true;
  };

  const disableSwitch = (index = 0) => {
    if (type === `loaner`) return false;
    else return true;
  };

  function validateForm() {
    resetValidation();
    try {
      const _moves = [...moves];
      _moves.forEach((move, i) => {
        if ((type === 'one-way' || type === 'concierge') && i > 0) return;
        if (!move.pickup) handleValidation(i, 'pickup', false);
        if (!move.delivery) handleValidation(i, 'delivery', false);
        if (move.pickup && !move.pickup.region_id && move.delivery && !move.delivery.region_id) {
          handleValidation(i, 'region', false);
        }
        if (!move.vehicle.vin || move.vehicle.vin === null) handleValidation(i, 'vin', false);
        if (!move.vehicle.make) handleValidation(i, 'make', false);
        if (!move.vehicle.model) handleValidation(i, 'model', false);
        if (!move.vehicle.year) handleValidation(i, 'year', false);
        if (move.pickupTime < moment()) handleValidation(i, 'ptime', false);
        if (type === 'concierge' && i === 0) {
          if (!move.consumer.name || move.consumer.name.trim().length < 1) handleValidation(i, 'cname', false);
          if (!move.consumer.phone || move.consumer.phone.trim().length < 1) handleValidation(i, 'cnumber', false);
        }
        if (type === 'loaner' && i === 1) {
          if (!move.consumer.name || move.consumer.name.trim().length < 1) handleValidation(i, 'cname', false);
          if (!move.consumer.phone || move.consumer.phone.trim().length < 1) handleValidation(i, 'cnumber', false);
        }
      });
    } catch (err) {
      console.error('Could not validate move form:', err.toString());
    }
  }

  const isFormValid = () => {
    validateForm();
    try {
      //Loop over all moves and check for structural integrity that is
      // required for all moves regardless of type
      for (let index = 0; index < moveCount; index++) {
        if (!isVehicleFound(moves[index], index)) {
          log && console.log(`move #${index} missing vehicle info`);
          return false;
        }
        if (!moves[index].hasOwnProperty('consumerPickup')) {
          log && console.log(`move #${index} missing consumer pickup flag`);
          return false;
        }
        if (moves[index].pickupTime < moment()) {
          log && console.log(`move #${index} pickup time is in the past`);
          return false;
        }
      }

      //For concierge, the first move must have consumer info
      if (type === 'concierge') {
        if (!isConsumerFound(moves[0])) {
          log && console.log('Concierge move missing consumer');
          return false;
        }
      }

      //For concierge+loaner, the second move must have consumer info
      if (type === 'loaner') {
        if (!isConsumerFound(moves[1])) {
          log && console.log('Loaner move missing consumer');
          return false;
        }
      }

      //Check to make sure at leaset one location has a region
      if (moves[0].pickup && !moves[0].pickup.region_id && moves[0].delivery && !moves[0].delivery.region_id) {
        toast.error(`At least one of the selected locations must be within the Hopdrive service radius.`);
        return false;
      }
    } catch (error) {
      console.error('Form validation crashed', error);
      return false;
    }

    return true;
  };

  const isLaneFound = move => {
    if (!move.hasOwnProperty('lane')) {
      log && console.log(`Move ${move.index} missing lane property`);
      return false;
    }
    if (move.lane == null) {
      log && console.log(`Move ${move.index} lane is null`);
      return false;
    }
    if (Object.keys(move.lane).length === 0) {
      log && console.log(`Move ${move.index} lane has no properties`);
      return false;
    }

    if (move.lane && move.lane.id <= 0) {
      log && console.log(`Move ${move.index} missing lane id`, move.lane);
      return false;
    }
    return true;
  };

  const isVehicleFound = (move, index) => {
    // Skip validation of loaner vehicle
    if (
      (type === `loaner` && index === 0 && sequenceA === 0) ||
      (type === `loaner` && index === 1 && sequenceA === 1)
    ) {
      return true;
    }

    // Non-loaner requirements
    if (!move.hasOwnProperty('vehicle')) return false;
    if (move.vehicle == null) return false;
    if (Object.keys(move.vehicle).length === 0) return false;
    if (!move.vehicle.vin) return false;
    if (!move.vehicle.year) return false;
    if (!move.vehicle.make) return false;
    if (!move.vehicle.model) return false;
    return true;
  };

  const isConsumerFound = move => {
    if (!move.hasOwnProperty('consumer')) return false;
    if (move.consumer == null) return false;
    if (Object.keys(move.consumer).length === 0) return false;

    if (!move.consumer.name) return false;
    if (!move.consumer.phone) return false;
    return true;
  };

  const handleFinishPlan = async () => {
    try {
      let valid = await isFormValid();
      let laneValid = true;

      if (!valid) {
        toast.warning('Please ensure highlighted fields are valid before continuing...');
        log && console.log(`form is invalid`);
        return;
      }

      // //Lane Section
      try {
        if (!lanePair || !lanePair[0] || !lanePair[1]) {
          toast.warning(`Move is missing its lane. Please try again. If the problem persists please contact support.`, {
            autoClose: 7500,
          });
          return;
        }
        moves[0].lane = lanePair[0];
        moves[1].lane = lanePair[1];
      } catch (err) {
        console.error('Error handling lanes', err);
        toast.warning(`Move is missing it's lane. Please try again. If the problem persists please contact support.`, {
          autoClose: 7500,
        });
        return;
      }

      for (let index = 0; index < moveCount; index++) {
        if (!isLaneFound(moves[index])) {
          log && console.log(`move #${index} missing lane info`);
          laneValid = false;
        }
      }

      if (!laneValid) {
        toast.warning(`Move is missing it's lane. Please try again. If the problem persists please contact support.`, {
          autoClose: 7500,
        });
        log && console.log(`form is invalid`);
        return;
      }

      log && console.log(`form is valid`);

      let insertableMoves = [];
      for (let index = 0; index < moveCount; index++) {
        const insertableMove = buildInsertableMove(moves[index], index);
        if (insertableMove) insertableMoves.push(insertableMove);
      }

      if (insertableMoves.length > 0) {
        setLoading(true);
        toast.info(`Please wait, your move(s) are being created...`);
        log && console.log(`Moves to insert:`, insertableMoves);
        const insertedMove = await insertMoves(insertableMoves);

        if (insertedMove) {
          clearForm();
          props.history.push({
            pathname: '/',
            state: { insertedMove: insertedMove },
          });
        }
      } else {
        log && console.log('There are no insertable moves to insert. Did they fail to generate?');
      }
    } catch (error) {
      console.error('handleFinishPlan crashed', error);
    }

    setLoading(false);
  };

  const buildInsertableMove = (move, index) => {
    log && console.log(`Building insertable move`, move);
    // Set consumer info for concierge & concierge + loaner
    let consumerName = null;
    let consumerPhone = null;
    let consumerAtPickup = 0;
    let consumerType = null;
    // =============== Init these to their default conditionals, then change as needed below ================ //
    let classOverride = roundTripMoveTypes.includes(type) ? 1 : 0;
    let moveClass = roundTripMoveTypes.includes(type) && move.consumerPickup === false ? 'base' : 'stranded';
    // ====================================================================================================== //
    if (type === `loaner` && index === 0) {
      consumerName = moves[1].consumer.name;
      consumerPhone = moves[1].consumer.phone;
    } else if (move.consumerPickup) {
      consumerName = move.consumer.name;
      consumerPhone = move.consumer.phone;
    } else {
      consumerName = null;
      consumerPhone = null;
    }
    if (index === 0 && type === 'concierge') {
      consumerLocation === 'pickup' ? (consumerAtPickup = 1) : (consumerAtPickup = 0);
      consumerType = 'customer'; //A concierge type move always has consumer_type "customer"
    }
    if (index === 0 && type === 'loaner') {
      consumerAtPickup = 0; // consumer is always the second move (delivery)
      sequenceA === 0 ? (consumerType = 'loaner') : (consumerType = 'customer'); // sequenceA = 0 is when the first move is a loaner
      moveClass = 'stranded'; // Need concierge + loaner moves to always be stranded
      classOverride = 1; // Need concierge + loaner moves to always be stranded
    }
    if (index === 1 && type === 'loaner') {
      consumerAtPickup = 1; // consumer is always the second move (delivery)
      sequenceB === 0 ? (consumerType = 'loaner') : (consumerType = 'customer'); // sequenceB = 0 is when the second move is a loaner
      moveClass = 'stranded'; // Need concierge + loaner moves to always be stranded
      classOverride = 1; // Need concierge + loaner moves to always be stranded
    }

    // Figure out SLA duration, then build the pickup and delivery time
    const slaHrs = workflow && workflow.sla ? workflow.sla.duration_hrs : defaultSla.duration_hrs;
    const pickupTime = move.pickupTime ? dayjs(move.pickupTime.format()).format() : null; // Make a mutable version of pickup time
    const deliveryTime = move.pickupTime ? dayjs(move.pickupTime.format()).add(slaHrs, `hour`).format() : null; // Make a mutable version of delivery time

    // Override delivery time for concierge moves
    const conciergeDeliveryTime = move.pickupTime
      ? dayjs(move.pickupTime.format()).add(conciergeSla.duration_hrs, `hour`).format()
      : null;

    // Create a move that can be inserted with a GQL mutation from
    // the data collected into adhoc move objects from the form
    try {
      return {
        chargeable: consumerType === `loaner` ? false : true,
        class: moveClass,
        config: { emailsToNotify: emailsList },
        consumer_at_pickup: consumerAtPickup,
        consumer_name: consumerName,
        consumer_phone: consumerPhone,
        consumer_pickup: move.consumerPickup ? move.consumerPickup : false,
        consumer_type: consumerType,
        createdBy: getUserEmail(),
        customer_id: customerId,
        dealer_contact: move.dealer_contact ? move.dealer_contact : null,
        deliver_by: move.consumerPickup ? conciergeDeliveryTime : deliveryTime,
        delivery_workflow_id: workflow.delivery_workflow_id || defaultWorkflow.delivery_workflow_id,
        lane_id: move.lane && move.lane.id ? move.lane.id : null,
        manual_flag: move.vehicle && move.vehicle.manual ? move.vehicle.manual : false,
        move_details: move.notes || null,
        payer_id: !payerId || customerId === payerId ? null : payerId,
        pickup_time: pickupTime,
        pickup_workflow_id: workflow.pickup_workflow_id || defaultWorkflow.pickup_workflow_id,
        rate_class_override: classOverride,
        ready_by: pickupTime,
        reference_num: move.vehicle && move.vehicle.refNum ? move.vehicle.refNum.trim() : null,
        sequence: index + 1,
        sla_id: move.consumerPickup ? conciergeSla.id : workflow && workflow.sla ? workflow.sla.id : defaultSla.id,
        updatedBy: getUserEmail(),
        vehicle_color: move.vehicle && move.vehicle.color ? move.vehicle.color.trim() : null,
        vehicle_make: move.vehicle && move.vehicle.make && move.vehicle.make.name ? move.vehicle.make.name.trim() : null,
        vehicle_model: move.vehicle && move.vehicle.model && move.vehicle.model.name ? move.vehicle.model.name.trim() : null,
        vehicle_stock:
          move.vehicle && move.vehicle.stock && move.vehicle.stock.vehicle_stock
            ? move.vehicle.stock.vehicle_stock.trim()
            : consumerType && consumerType === 'customer'
            ? 'consumer'
            : null,
        vehicle_vin:
          move.vehicle && move.vehicle.vin && move.vehicle.vin.vehicle_vin
            ? move.vehicle.vin.vehicle_vin.toUpperCase().trim()
            : 'No Vin Provided',
        vehicle_year: move.vehicle && move.vehicle.year ? move.vehicle.year.trim() : null,
        workflowset_id: workflow.id || defaultWorkflow.id,
      };
    } catch (error) {
      console.error('Crashed trying to create insertable move', error);
      return null;
    }
  };

  const insertMoves = async movesArray => {
    log && console.log(`Attempting to insert moves array...`, movesArray);
    //TODO: better parent/child determination
    if (Array.isArray(movesArray) && movesArray.length === 2) {
      const movePairRes = await insertMovePair(movesArray[0], movesArray[1]);
      console.log('movePairRes', movePairRes);
      if (!movePairRes) {
        return false;
      }
      try {
        // Check if any of the inserted moves are associated with a consumer address
        // If so, update the related location to reflect a type of 'consumer residential'
        let firstMove = Array.isArray(movePairRes) ? movePairRes[0] : null;
        let consumerMove = firstMove && Array.isArray(firstMove.childMoves) ? firstMove.childMoves[0] : null;
        if (moveCount > 1 && (type === 'loaner') & firstMove && consumerMove) {
          // C+L moves will always have a consumer address at the pickup of the consumer move
          if (consumerMove.lane.pickup.type !== 'consumer residential')
            updateLocationType(consumerMove.lane.pickup.id, 'consumer residential');
        } else if (type === 'concierge' && firstMove.consumer_type === 'customer') {
          // Concierge moves will have a consumer address at the pickup if the consumer_at_pickup flag is true
          if (firstMove.consumer_at_pickup === 1) {
            if (firstMove.lane.pickup.type !== 'consumer residential')
              updateLocationType(firstMove.lane.pickup.id, 'consumer residential');
          } else {
            if (firstMove.lane.delivery.type !== 'consumer residential')
              updateLocationType(firstMove.lane.delivery.id, 'consumer residential');
          }
        }
      } catch (err) {
        console.error(`Failed to update consumer location:`, err);
      }
      return movePairRes;
    }
    try {
      const res = await ctx.apolloClient.mutate({
        mutation: INSERT_MOVES,
        variables: { movesObjectArray: movesArray },
      });
      if (res.data && res.data.insert_moves.returning.length > 0) {
        log && console.log(`>> INSERT moves array success:`, res.data.insert_moves);
        toast.success(`Your move has been created. A dispatcher is working to assign a driver.`);
        return true;
      }
    } catch (err) {
      console.error(`Failed to insert move:`, err);
      toast.error(
        `Failed to create move(s). Please ensure that you've entered all the correct data and resubmit. If the problem persists, please contact your representative.`
      );
    }
    return false;
  };

  const insertMovePair = async (parentMove, childMove) => {
    log && console.log(`Attempting to insert moves array...`, parentMove, childMove);
    // const nestedMoveObj = createNestedMoveObj(parentMove, childMove);
    parentMove.childMoves = { data: [childMove] };
    const nestedMoveObj = [parentMove];
    log && console.log('parentMove', parentMove);
    try {
      const res = await ctx.apolloClient.mutate({
        mutation: INSERT_NESTED_MOVE_PAIR,
        variables: { nestedMoveObj: nestedMoveObj },
      });
      if (res.data && res.data.insert_moves.returning.length > 0) {
        console.log('Pair RES', res.data.insert_moves);
        return res.data.insert_moves.returning;
      }
    } catch (err) {
      console.error('Error inserting pair of moves', err);
      return null;
    }
  };

  const updateLocationType = async (locationId, type) => {
    try {
      ctx.apolloClient.mutate({
        mutation: UPDATE_LOCATION_TYPE,
        variables: {
          id: locationId,
          type: type,
        },
      });
    } catch (error) {
      console.error("Failed to update the location object's type:", error);
    }
  };

  if (loading) return <Loading fixed />;
  return (
    <Query query={GET_CUSTOMER_AND_WORKFLOWSETS} variables={{ customerId: customerId ? customerId : 0 }}>
      {({ loading, error, data }) => {
        //If this fails, it will default to the regular form and allow adding moves
        if (loading) return <Loading fixed />;
        if (error) console.error(`Failed to retrieve customer config:`, error);

        // Set customer config
        let customer = data && data.customers && data.customers.length ? data.customers[0] : null;
        let customerConfig = sdk.configs.enrichCustomerConfig(
          getPropValue(customer, `config`),
          getPropValue(customer, `organization.config`)
        );

        // Set payer ID
        let defaultPayerId = customerConfig && customerConfig.default_payer ? customerConfig.default_payer : null;
        if (defaultPayerId) {
          setPayerId(defaultPayerId);
        }

        // Set workflowsets
        let workflowSets = data && data.workflowsets && data.workflowsets.length ? data.workflowsets : [];
        if (customerConfig && customerConfig.public_workflowsets === false) {
          workflowSets = workflowSets.filter(wfs => !wfs.public);
        }

        // Store workflowset in state
        if (!workflow.id && customerConfig && workflowSets.length) {
          if (customerConfig.default_workflow_set_id) {
            const configWorkflowSet = workflowSets.find(
              wfs => wfs.id === getPropValue(customerConfig, `default_workflow_set_id`)
            );
            if (configWorkflowSet) setWorkflow(configWorkflowSet);
          } else {
            setWorkflow(workflowSets[0]);
          }
        } else if (!workflow.id) {
          setWorkflow(defaultWorkflow);
        }

        // Check config for disabling the move planner
        if (
          data &&
          data.customers &&
          data.customers.length > 0 &&
          data.customers[0].config &&
          data.customers[0].config.add_moves_disabled === true
        ) {
          return <DisabledMovePlanner />;
        } else {
          return (
            <>
              <div className={cls.root}>
                {ctx && ctx.firebaseUser && (
                  <Container maxWidth='lg'>
                    <Grid container spacing={2} alignItems='stretch'>
                      <Grid item md={6} xs={12}>
                        <Typography className={cls.head}>Move Planner</Typography>
                        <Typography className={cls.sub}>
                          Welcome to the move planner. This form allows you to set up a plan to move your vehicles.
                          When&nbsp;the pickup time arrives, one of our drivers will be ready to move your vehicle(s)
                          for you. Be&nbsp;sure to have someone meet our driver at both ends for&nbsp;inspection.
                        </Typography>
                      </Grid>
                      {/* Only show payer select dropdown if user has allowed payer array*/}
                      {payersArray && payersArray.length > 0 ? (
                        <PayerSelect
                          payerId={payerId}
                          onPayerChange={setPayerId}
                          payersArray={payersArray}
                          requireThirdPartyPayer={(customerConfig && customerConfig.require_third_party_payer) || false}
                        />
                      ) : null}
                    </Grid>

                    <Divide spacer tip='Choose a move type to prepare the form.'>
                      Type
                    </Divide>
                    <TypeForm type={type} onTypeChange={handleTypeChange} />

                    {((userRole && userRole === 'admin') ||
                      (userRole && userRole === `dealer-admin`) ||
                      (userRole && userRole === `dealer-super-admin`)) &&
                    payeesArray &&
                    payeesArray.length ? (
                      <>
                        <Divide
                          spacer
                          tip='Choose a rooftop to assign this move to. If you are unsure, please leave this as the default.'
                        >
                          Rooftop
                        </Divide>
                        <CustomerForm
                          customerId={customerId}
                          customerIdArray={payeesArray}
                          onCustomerIdChange={handleCustomerIdChange}
                        />
                      </>
                    ) : null}

                    <div style={{ display: moveCount ? `block` : `none` }}>
                      <Divide
                        spacer
                        tip='Choose an origin and destination to create a lane between the two. If the location is not in our system, you may choose a Google-suggested address and create a new location.'
                      >
                        Lane
                      </Divide>
                      <Subscription subscription={GET_LANE_BY_ID} skip={subscriptionSkip} variables={{ id: newLaneId }}>
                        {({ loading, error, data }) => {
                          return (
                            <LaneForm
                              validation={moves[0].validation}
                              defaultLane={defaultLane}
                              lane={lane}
                              setLane={setLane}
                              onLaneChange={handleLaneChange}
                              handleLanes={handleLanes}
                              subscriptionSkip={subscriptionSkip}
                              setSubscriptionSkip={setSubscriptionSkip}
                              getLaneByLocationIds={getLaneByLocationIds}
                              customerId={customerId}
                              lanePair={lanePair}
                              setLanePair={setLanePair}
                              doesLaneExist={doesLaneExist}
                              setDoesLaneExist={setDoesLaneExist}
                              type={type}
                              newLaneId={newLaneId}
                              subscriptionData={data && data.lanes && data.lanes.length > 0 ? data.lanes : null}
                              customerConfig={customerConfig}
                              isSubscriptionRendering={isSubscriptionRendering}
                              setIsSubscriptionRendering={setIsSubscriptionRendering}
                              pickupDeliveryChange={pickupDeliveryChange}
                            />
                          );
                        }}
                      </Subscription>
                    </div>

                    {moves.map((move, moveIndex) => (
                      <div
                        key={`move-plan-${moveIndex}`}
                        style={{ display: moveIndex < moveCount || moveCount === null ? `block` : `none` }}
                      >
                        <Divide spacer tip={getSectionTip(moveIndex)}>
                          {getSectionTitle(moveIndex)}
                        </Divide>
                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <div className={cls.paperWhite}>
                              {type === 'concierge' ? (
                                <>
                                  <ConsumerLocButtonGroup
                                    consumerLocation={consumerLocation}
                                    setConsumerLocation={setConsumerLocation}
                                    switchLabelA={`Picking Up From Customer`}
                                    switchLabelB={`Delivering To Customer`}
                                  />
                                </>
                              ) : (
                                <>
                                  <LocationSelect
                                    valid={move.validation && move.validation.pickup ? move.validation.pickup : false}
                                    locationData={move.pickup ? move.pickup : null}
                                    onChange={handleLocationChange(moveIndex)}
                                    label='Pickup Location'
                                  />
                                </>
                              )}

                              <WorkflowForm
                                workflowSets={workflowSets}
                                workflow={workflow}
                                onWorkflowChange={setWorkflow}
                                isConcierge={type === 'concierge' || type === 'loaner'}
                                conciergeSla={conciergeSla}
                              />

                              <div className={cls.break} />

                              <DatetimeSelect
                                validation={move.validation}
                                timeData={move.pickupTime}
                                onChange={handleTimeChange(moveIndex)}
                                tip={getDatetimeTip(moveIndex)}
                                useUtc
                                disabled={disableTimeSelect(moveIndex)}
                                getDefaultStartTime={getStartTime}
                              />

                              <div className={cls.break} />

                              {!disableCustomerNotes(moveIndex) ? (
                                <>
                                  <ConsumerInfoForm
                                    validation={move.validation}
                                    consumerData={move.consumer}
                                    onChange={handleFormChange(`consumer`, moveIndex)}
                                  />

                                  <div className={cls.break} />
                                </>
                              ) : null}

                              <DealerContactInput
                                defaultContact={move.dealer_contact}
                                onChange={handleFormChange(`dealer_contact`, moveIndex)}
                              />

                              <div className={cls.break} />

                              <NotesInput defaultNotes={move.notes} onChange={handleFormChange(`notes`, moveIndex)} />
                            </div>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <div className={cls.paperGray}>
                              <div style={{ display: !disableSwitch(moveIndex) ? `block` : `none` }}>
                                <SequenceButtonGroup
                                  sequence={moveIndex === 0 ? sequenceA : sequenceB}
                                  onChange={handleSwitchChange(moveIndex)}
                                  switchLabelA={`Loaner Vehicle`}
                                  switchLabelB={`Customer Vehicle`}
                                />
                                <div className={cls.break} />
                              </div>

                              <VehicleForm
                                validation={move.validation}
                                vehicleData={move.vehicle}
                                onChange={handleFormChange(`vehicle`, moveIndex)}
                                customerId={customerId}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ))}

                    {customerConfig &&
                    customerConfig.allow_email_notifications &&
                    customerConfig.allow_email_notifications === true ? (
                      <div>
                        <Divide
                          spacer
                          tip='Optionally add up to 5 emails to receive notification when this move has been picked up and delivered successfully'
                        >
                          Notifications
                        </Divide>
                        <NotificationManagement emailsList={emailsList} setEmailsList={setEmailsList} editMode={true} />
                      </div>
                    ) : null}

                    <div className={cls.actions}>
                      <Button
                        className={cls.action}
                        data-testid='submit-plan-button'
                        variant='outlined'
                        color='primary'
                        size='large'
                        onClick={() => handleFinishPlan()}
                      >
                        Submit Plan
                      </Button>
                    </div>
                  </Container>
                )}
              </div>
            </>
          );
        }
      }}
    </Query>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  paperWhite: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  paperGray: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.75),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
      fontSize: '18px',
    },
  },
  sub: {
    maxWidth: '640px',
    marginBottom: '-16px',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-12px',
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-8px',
      fontSize: '12px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: theme.spacing(4),
  },
  action: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  block: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '56px',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const GET_LANES_BY_LOCATIONS = gql`
  query get_lanes_by_locations($pickupId: bigint!, $deliveryId: bigint!) {
    lanes(
      where: { origin_location_id: { _eq: $pickupId }, destination_location_id: { _eq: $deliveryId } }
      order_by: [{ favorite: desc }, { description: asc }]
    ) {
      ...Lane
    }
  }
  ${fragments.lane}
`;

const INSERT_MOVES = gql`
  mutation insert_moves($movesObjectArray: [moves_insert_input!]!) {
    insert_moves(objects: $movesObjectArray) {
      returning {
        ...Move
      }
    }
  }
  ${fragments.move}
`;

const INSERT_NESTED_MOVE_PAIR = gql`
  mutation insert_moves($nestedMoveObj: [moves_insert_input!]!) {
    insert_moves(objects: $nestedMoveObj) {
      returning {
        ...Move
        childMoves {
          ...Move
        }
      }
    }
  }
  ${fragments.move}
`;

const UPDATE_LOCATION_TYPE = gql`
  mutation update_location_type($id: bigint!, $type: String!) {
    update_locations(where: { id: { _eq: $id } }, _set: { type: $type }) {
      affected_rows
    }
  }
`;

const GET_CUSTOMER_AND_WORKFLOWSETS = gql`
  query get_customer_and_workflowsets_move_planner($customerId: bigint!) {
    customers(where: { id: { _eq: $customerId } }) {
      id
      config
      organization {
        id
        config
      }
    }

    workflowsets(
      where: {
        _or: [
          { customer_id: { _eq: $customerId } }
          { organization: { customers: { id: { _eq: $customerId } } } }
          { public: { _eq: true } }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      name
      description
      pickup_workflow_id
      delivery_workflow_id
      public
      sla {
        id
        description
        duration_hrs
        label
        name
        public
      }
    }
  }
`;

const GET_LANE_BY_ID = gql`
  subscription get_lanes_by_id($id: bigint!) {
    lanes(where: { id: { _eq: $id } }) {
      id
      customer_id
      dealer_base_price
      dealer_stranded_price
      distance_miles
    }
  }
`;
